import { useCookies } from '@vueuse/integrations/useCookies.mjs'

const { get } = useCookies()
export default defineNuxtRouteMiddleware((to, from) => {
  const needAuth = !get('token')
  const isFromAuth = from.fullPath.indexOf('/auth') !== -1
  const isToAuth = to.fullPath.indexOf('/auth') !== -1
  const isToLogin = to.fullPath.indexOf('/login') !== -1
  const isToHomepage = to.fullPath === '/'

  if (needAuth) {
    if (!isToHomepage) {
      if (!isToLogin && !isFromAuth) {
        const homepageTrigger = to.query.from === 'homepage'

        return navigateTo(`/login${homepageTrigger ? '?from=homepage' : ''}`)
      }
    }
  } else {
    if (isToLogin || isToAuth) {
      return navigateTo('/bookmarks')
    }
  }
})
