import revive_payload_client_OWVy8eWklt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_gt7YifLbIJ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fdvXjaGZhs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_j4jlXqjTLR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_gjzZijPgDl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DOHONde6gm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_PCZYw37vba from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_P7Rn0bSThD from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.2_typescript@5.6.2_vue@3.5.3_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/apps/slax-reader-dweb/.nuxt/components.plugin.mjs";
import prefetch_client_alDO1dhXQh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_encoding@0.1.13_eslint@8.57.0_ioredis@5._tv4ugokwsg7knt5rdqpbtwcpb4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/opt/buildhome/repo/apps/slax-reader-dweb/.nuxt/unocss.mjs";
import switch_locale_path_ssr_OFzYHf4NlF from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.4_rollup@4.21.2_vue@3.5.3_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_GSxwK5UIYG from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.4_rollup@4.21.2_vue@3.5.3_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_tAxsKdGYJU from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.2_typesc_7dqykuuawdqyffotxdsg6ws7lq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  revive_payload_client_OWVy8eWklt,
  unhead_gt7YifLbIJ,
  router_fdvXjaGZhs,
  payload_client_j4jlXqjTLR,
  navigation_repaint_client_gjzZijPgDl,
  check_outdated_build_client_DOHONde6gm,
  chunk_reload_client_PCZYw37vba,
  plugin_vue3_P7Rn0bSThD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_alDO1dhXQh,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_OFzYHf4NlF,
  i18n_GSxwK5UIYG,
  plugin_tAxsKdGYJU
]