import { default as authdKSdIKWsZOMeta } from "/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/auth.vue?macro=true";
import { default as _91id_931hY2EzvVKRMeta } from "/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/bookmarks/[id].vue?macro=true";
import { default as index815lZF0zuoMeta } from "/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/bookmarks/index.vue?macro=true";
import { default as indexaiAbqYFvv9Meta } from "/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/index.vue?macro=true";
import { default as loginUrLcGQQANdMeta } from "/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/login.vue?macro=true";
import { default as userpgr9KYtBilMeta } from "/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/user.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "bookmarks-id",
    path: "/bookmarks/:id()",
    component: () => import("/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/bookmarks/[id].vue").then(m => m.default || m)
  },
  {
    name: "bookmarks",
    path: "/bookmarks",
    component: () => import("/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/bookmarks/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/opt/buildhome/repo/apps/slax-reader-dweb/src/pages/user.vue").then(m => m.default || m)
  }
]